import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {PostData} from '../../services/PostData';

import './Login.css';

import { withSnackbar } from 'notistack';

import db from '../../db';

class Logout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            redirectToReferrer: false
        };

        this.logout = this.logout.bind(this);
        this.logout_bruto = this.logout_bruto.bind(this);

    }

    logout_bruto(){
          
            db.delete().then(() => {
                db.open();
               this.logout();
            }).catch((err) => {
                console.error("Could not delete database");
            }).finally(() => {
                // Do what should be done next...
            });
    
    }

    logout() {

       
            localStorage.removeItem('userData');
            localStorage.removeItem('modulos');
            
            localStorage.removeItem('tarefas');
            localStorage.removeItem('locais');
            localStorage.removeItem('habituais');

           localStorage.removeItem('admin_encs');
          
            
            PostData('login/login.php', this.state).then((result) => {});
             this.props.history.push("/login");
      

    }

    render() {

        if (localStorage.getItem('userData')) {
            return (
                    <div className="row" id="Body">
                      <h4>Logout</h4>
                            <input type="submit" className="button success" value="logout" onClick={this.logout}/>
                    
                            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                      <p>Não utilizar este botão a menos que seja pedido explícitamente para resolver problemas de atualização.</p>
                      <input type="submit" className="button success" value="Atenção - Irá remover os dados locais" onClick={this.logout_bruto}/>
                      <br/><br/>
                    </div>
                    )
        } else {
           return (<Redirect to={'/login'}/>)
       //  console.log()
        }


    }

    /*
     login(username, password) {
     const requestOptions = {
     method: 'POST',
     headers: { 'Content-Type': 'application/json' },
     body: JSON.stringify({ username, password })
     };
     
     return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
     .then(handleResponse)
     .then(user => {
     // login successful if there's a jwt token in the response
     if (user.token) {
     // store user details and jwt token in local storage to keep user logged in between page refreshes
     localStorage.setItem('user', JSON.stringify(user));
     }
     
     return user;
     });
     }
     
     logout() {
     // remove user from local storage to log user out
     localStorage.removeItem('user');
     }
     
     handleResponse(response) {
     return response.text().then(text => {
     const data = text && JSON.parse(text);
     if (!response.ok) {
     if (response.status === 401) {
     // auto logout if 401 response returned from api
     logout();
     location.reload(true);
     }
     
     const error = (data && data.message) || response.statusText;
     return Promise.reject(error);
     }
     
     return data;
     });
     }
     
     render() {
     return (
     <div>
     login
     </div>
     );
     }
     
     */
}

export default withSnackbar(Logout);