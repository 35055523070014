import React, { Component } from 'react';
import { PostData } from '../../services/PostData';
import Select from 'react-select';

import { askForPermissioToReceiveNotifications } from '../../push-notifications.js';
import { Redirect } from 'react-router-dom';

import db from '../../db';

import { NavLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});


class Encomendas extends Component {

  constructor(props) {
    super(props);
    this.state = {
     encomendas:[]
    };
  
  }





  UNSAFE_componentWillMount() {

    this.props.enqueueSnackbar('A obter informação do servidor', { variant: 'success' });
 

    PostData('logistica/lista_encomendas.php', this.state).then((result) => {
      let responseJson = result;
      if (responseJson.error === 0) {
        this.props.enqueueSnackbar(responseJson.message, { variant: 'success' });
 this.setState({encomendas:responseJson.encomendas});
      } else {
        this.props.enqueueSnackbar(responseJson.message, { variant: 'error' });
 
      }

    })

    

  }





  render() {


    return (
      <div className="row" id="Body">
        <h4>
          Lista de Encomendas
              </h4>
     

      
        {this.state.encomendas.map((encomenda) => {
            console.log(encomenda);
        return <p><NavLink to={'/encomendas/entrada/?enc='+encomenda.id}>(Enc: {encomenda.id}) {encomenda.data} - {encomenda.obra}<br/>
         {encomenda.forn} <br/> {encomenda.descr}</NavLink></p>

        })
        }
        
        
        <br></br>
</div>

    );
  }
}


export default withSnackbar(withStyles(styles)(Encomendas));
