import React, { Component } from 'react';
import { PostData } from '../../services/PostData';

import { Redirect } from 'react-router-dom';

import db from '../../db';

import { NavLink } from 'react-router-dom';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import DateInput from '../Helpers/dateinput';


import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

const queryString = require('query-string');


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

var _locais = [];

class InserirIntervencao extends Component {

  constructor(props) {
    super(props);

    const parsed = queryString.parse(props.location.search);
    //  console.log(parsed);

    this.state = {
      maq: parsed.maq,
      obs: '',
      atividade: 0,
      horas_km: '',
      local: 0,
      proxima:'',
      submitbtndisabled: false,
      tempo:0

    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {

    //  console.log(event);
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSelect(event) {

    //     console.log(event);
    this.setState({
      atividade: event.value
    });
  }

  handleSubmit() {
    this.setState({ submitbtndisabled: true });

    PostData('mecanica/insere_revisao.php', this.state).then((result) => {

      //  console.log('resposta');
      //  console.log(result);

      let responseJson = result;

      if (responseJson.error === 0) {
        this.props.enqueueSnackbar(responseJson.message, { variant: 'success' });
        //     console.log(responseJson);

      } else {
        if (responseJson.error < 100) {
          this.props.enqueueSnackbar(responseJson.message, { variant: 'error' });
          this.setState({ submitbtndisabled: false });
        } else {
          this.props.enqueueSnackbar('Falha de ligação, tente mais tarde', { variant: 'error' });
          this.setState({ submitbtndisabled: false });
        }


      }
    }).catch((error) => {
      this.props.enqueueSnackbar(error.message, { variant: 'error' });
      this.setState({ submitbtndisabled: false });
    });
  }

  UNSAFE_componentWillMount() {
    var locais = [];
    var locais2 = JSON.parse(localStorage.getItem('locais'));
    Object.entries(locais2).forEach(entry => {
      locais[entry[1].id] = entry[1].descr;
    });
    _locais = locais;

  }





  render() {


    return (
      <div className="row" id="Body">
        <h4>Registo de Intervenção</h4>

        <FormControl>
          <InputLabel htmlFor="atividade">Tipo de Intervenção</InputLabel>
          <Select
            value={this.state.atividade}
            onChange={this.handleChange}
            inputProps={{
              name: 'atividade',
              id: 'atividade',
            }}
          >
            <MenuItem key="0" value="0">
              <em>Escolha</em>
            </MenuItem>
            <MenuItem key="10" value="10">
              <em>Revisão</em>
            </MenuItem>
            <MenuItem key="11" value="11">
              <em>Reparação</em>
            </MenuItem>
            <MenuItem key="12" value="12">
              <em>Manutenção</em>
            </MenuItem>

            <MenuItem key="-1" value="-1">
              Outro (Preencher Obs)
                            </MenuItem>

          </Select>
        </FormControl>



        <br />


        <FormControl>
          <InputLabel htmlFor="local">Local</InputLabel>
          <Select
            value={this.state.local}
            onChange={this.handleChange}
            inputProps={{
              name: 'local',
              id: 'local',
            }}
          >
            <MenuItem key="0" value="0">
              <em>Escolha</em>
            </MenuItem>
            {_locais.map((local, id) =>
              <MenuItem key={id} value={id}>{local}</MenuItem>
            )};
                             <MenuItem key="-1" value="-1">
              Outro (Preencher Obs)
                            </MenuItem>

          </Select>
        </FormControl>



        <br />

        <FormControl>
          <TextField
            id="horas_km"
            label="Km/Horas da máquina"
            name="horas_km"
            value={this.state.horas_km}
            onChange={this.handleChange}
            margin="normal"
            multiline={false}
            rows={3}

            rowsMax={8}
          />
        </FormControl>


        <br />


        <FormControl>
          <TextField
            id="obs"
            label="Observações"
            name="obs"
            value={this.state.obs}
            onChange={this.handleChange}
            margin="normal"
            multiline={true}
            rows={3}

            rowsMax={8}
          />
        </FormControl>

        <br />

        <FormControl>
          <TextField
            id="tempo"
            label="Horas de Intervenção"
            name="tempo"
            value={this.state.tempo}
            onChange={this.handleChange}
            margin="normal"
            multiline={false}
            rows={3}

            rowsMax={8}
          />
        </FormControl>


        <br />

        <FormControl>
          <TextField
            id="proxima"
            label="Próxima Revisão Prevista"
            name="proxima"
            value={this.state.proxima}
            onChange={this.handleChange}
            margin="normal"
            multiline={false}
            rows={3}

            rowsMax={8}
          />
        </FormControl>


        <br />

        <br />

        <button onClick={this.handleSubmit} disabled={this.state.submitbtndisabled} >
          {this.state.submitbtndisabled ? '...' : 'Registar'}
        </button>
        <br />
        <br />
        <p>
          <NavLink to={"/mecanica/listamaquinas"}>Voltar</NavLink>
        </p>

      </div>


    );
  }
}


export default withSnackbar(withStyles(styles)(InserirIntervencao));
