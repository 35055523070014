import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { PostData } from '../../services/PostData';
//import Select from 'react-select';
import { NavLink } from 'react-router-dom';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from '@material-ui/core/Checkbox';
//import moment from 'moment';

import { withSnackbar } from 'notistack';

import db from '../../db';

import { horaMinuto } from '../Helpers/helpers';
import DateInput from '../Helpers/dateinput';
//import { resolve } from 'path';

//import Modal from '@material-ui/core/Modal';
var funcs_ordem = [];
var selectedCheckboxes = new Set();

class Habituais extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data_longa: new Date(),
            data: new Date().toISOString().slice(0, 10),
            funcs: [],
            diarios: []
        };

        this.getHorasInfo = this.getHorasInfo.bind(this);
        this.handleData = this.handleData.bind(this);
        this.refresca = this.refresca.bind(this);
        this.enviaNegativos = this.enviaNegativos.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.redirectToInsert = this.redirectToInsert.bind(this);
        this.handleDateChangeRaw = this.handleDateChangeRaw.bind(this);
        this.registo_existe = this.registo_existe.bind(this);
    }




    handleData(event) {
        //      console.log(event);
        var d = event.toISOString().slice(0, 10);
        //    console.log(d);

        this.setState({
            data_longa: event,
            data: d
        }, () => this.refresca());

    }

    toggleCheckbox = name => event => {

        if (selectedCheckboxes.has(name)) {
            selectedCheckboxes.delete(name);
        } else {
            selectedCheckboxes.add(name);
        }

        //   console.log(selectedCheckboxes);
    };

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    componentWillMount() {

        PostData('funcionarios/lista_habituais.php', this.state).then((result) => {
            let responseJson = result;
            console.log(responseJson);
            if (responseJson.error === 0) {
                localStorage.setItem('habituais', JSON.stringify(responseJson.funcionarios));
            }
        });
        PostData('funcionarios/tarefas_habituais.php', this.state).then((result) => {
            let responseJson = result;
            //   console.log(responseJson);
            if (responseJson.error === 0) {
                localStorage.setItem('tarefas', JSON.stringify(responseJson.tarefas));
                localStorage.setItem('ot', JSON.stringify(responseJson.ot));
            }
        });
        PostData('locais/locais.php', this.state).then((result) => {
            let responseJson = result;
            //   console.log(responseJson);
            if (responseJson.error === 0) {
                localStorage.setItem('locais', JSON.stringify(responseJson.locais));
            }
        });

        PostData('mecanica/lista_maquinas_n.php', this.state).then((result) => {
            let responseJson = result;
            if (responseJson.error === 0) {
                localStorage.setItem('maquinas', JSON.stringify(responseJson.maquinas));
            }

        })

        var funcionarios = [];

        var teste = JSON.parse(localStorage.getItem('habituais'));

        Object.entries(teste).forEach(entry => {
            funcionarios[entry[1].id] = entry[1];
            funcs_ordem[entry[1].id] = entry[1].nome;
        });

        this.setState({ funcs: funcionarios });

        var obj2 = funcionarios;

        obj2.sort((a, b) => {

            if (a.nome === b.nome) {
                return 0;
            } else {
                //    console.log((a.nome).localeCompare(b.nome));
                return ((a.nome).localeCompare(b.nome) < 0 ? -1 : 1);
            }



        });


        this.selectedCheckboxes = new Set();

        //   funcs_ordem=funcionarios;
        //console.log(funcionarios);

        //console.log(funcs_ordem);

        this.refresca();
    };

    refresca() {
        //   console.log('refresca');
        //   console.log(this.state);

        db.table('horas').where("data").equals(this.state.data).toArray().then((lista) => {
            //   console.log(lista);
            //  this.setDiarios(lista);
            this.setState({ diarios: lista });
            // return(lista);
        }).catch(function (error) { console.log(error) });



    }

    async enviaNegativos(lista) {
        let promises = [];
        let i;
        for (i = 0; i < lista.length; i++) {
            //  console.log(lista[i]);
            if (lista[i].hora_id > -1550000000000) {

                lista[i].hora_id = (-1) * lista[i].hora_id;

            }
            if (lista[i].hora_id < -1550000000000) {

                lista[i].hora_id = 0;

            }

            console.log('id a enviar:' + lista[i].hora_id);
            promises.push(PostData('funcionarios/registo_horas.php', lista[i]));
        }

        try {
            const results = await Promise.all(promises);
            //   console.log("All done", results);
        }
        catch (e) {
            console.log(e);
        }

    }

    getHorasInfo() {

        /* enviar negativos */

        //readdb está antes do update, verificar

        db.table('horas').where("hora_id").below(0).toArray().then((lista) => {
            //      console.log(lista);
            return this.enviaNegativos(lista);
        })
            .then(() => {
                PostData('funcionarios/lista_horas.php', this.state).then((result) => {

                    let responseJson = result;

                    //     console.log(responseJson);

                    if (responseJson.error !== 100) {

                        if (responseJson.error > 0) {
                            this.props.enqueueSnackbar(responseJson.message, { variant: 'error' });
                        } else {

                            db.horas.clear().then(() => {

                                for (var id in responseJson.horas) {
                                    //   console.log(id);
                                    //    console.log(responseJson.horas[id]);
                                    let hora = {
                                        hora_id: parseInt(responseJson.horas[id].id, 10),
                                        hora_maquina_final: responseJson.horas[id].hora_maquina_final,
                                        responsavel: responseJson.horas[id].responsavel,
                                        hora_maquina_inicial: responseJson.horas[id].hora_maquina_inicial,
                                        hora_saida: responseJson.horas[id].hora_saida,
                                        funcionario: responseJson.horas[id].funcionario,
                                        data: responseJson.horas[id].data,
                                        local: responseJson.horas[id].local,
                                        observacoes: responseJson.horas[id].observacoes,
                                        tipo_falta: responseJson.horas[id].tipo_falta,
                                        litros_combustivel: responseJson.horas[id].litros_combustivel,
                                        atividade: responseJson.horas[id].atividade,
                                        hora_entrada: responseJson.horas[id].hora_entrada,
                                        hora_entrada_longa: new Date(),
                                        hora_saida_longa: new Date(),
                                        hora_almoco: responseJson.horas[id].hora_almoco,
                                        hectares: responseJson.horas[id].hectares,
                                        trator: responseJson.horas[id].trator_apoio,
                                        maquina: responseJson.horas[id].maquina,
                                        maq_num_horas: responseJson.horas[id].maq_num_horas,
                                        local_entrega: responseJson.horas[id].local_entrega,
                                        local_recolha: responseJson.horas[id].local_recolha,
                                        dia_seguinte: responseJson.horas[id].dia_seguinte,
                                        horas_chuva: responseJson.horas[id].horas_chuva,
                                        ot: responseJson.horas[id].ot

                                    };

                                    db.table('horas').add(hora);
                                }

                            }).then(() => { this.refresca(); });
                            this.props.enqueueSnackbar(responseJson.message, { variant: 'success' });

                        }
                    } else {
                        this.props.enqueueSnackbar(responseJson.message, { variant: 'warning' });
                    }

                }).catch((error) => { this.props.enqueueSnackbar('Erro: ' + error, { variant: 'error' }); })

            });

        //PostData('funcionarios/registo_horas.php', this.state).then((result) => {


        /*obter resultados*/


    }

    redirectToInsert() {
        if (selectedCheckboxes.size > 0) {
            var linque = "/funcionarios/horas/" + '?func=multiple&funcs=' + [...selectedCheckboxes].join('|') + '&data=' + this.state.data + '&hora_id=0';
            //console.log(linque);
            window.location = linque;
        } else {
            this.props.enqueueSnackbar('Sem funcionários seleccionados.', { variant: 'warning' });
        }
    }

    registo_existe(id_func) {
        var encontrou = 0;
        var completo = 0;

        this.state.diarios.map((registo) => {
            if (registo.funcionario === id_func) {
                encontrou = 1;

                if (registo.hora_saida && registo.hora_entrada && (registo.hora_saida.length) > 2 && (registo.hora_entrada.length) > 2) {
                    completo = 1;
                }
            }
        });

        if (encontrou === 1) {
            if (completo === 1) {
                return <span style={{ color: 'green' }}> R</span>;
            } else {
                return <span style={{ color: 'orange' }}> R</span>;
            }

        } else {
            return '';
        }

        //  return 1;
        //  return 2;
    }


    render() {

        var data_tmp = this.state.data;

        if (localStorage.getItem('userData')) {
        } else {
            return (<Redirect to={'/login'} />);
        }

        return (
            <div className="row" id="Body">
                <br />
                <label>Data </label>
                <DatePicker
                    customInput={<DateInput />}
                    dateFormat="yyyy-MM-dd"
                    selected={this.state.data_longa}
                    onChange={this.handleData}
                />
                <input type="submit" className="button success" value="Obter dados do servidor" onClick={this.getHorasInfo} />

                <br />

                <h4>Registos</h4>

                <p>Legenda: S - Já no servidor. L - Local. Para enviar as alterações locais para o servidor clicar em "Obter dados do servidor".</p>
                <table id="registos_dia">
                    <thead>
                        <tr><th></th><th>Funcionário</th><th>Entrada</th><th>Saída</th></tr>
                    </thead>
                    <tbody>
                        {this.state.diarios.map((registo) => {


                            //   registo.hora_entrada + ' - ' + registo.hora_saida;
                            var linque2 = "/funcionarios/horas/" + '?func=' + registo.funcionario + '&data=' + data_tmp + '&hora_id=' + registo.hora_id;

                            var letra = <span style={{ color: 'green' }}>S</span>;
                            if (registo.hora_id < 0) {
                                letra = <span style={{ color: 'orange' }}>L</span>;
                            }


                            return <tr key={registo.id}>
                                <td>{letra}</td>
                                <td><NavLink to={linque2}>{funcs_ordem[registo.funcionario]}</NavLink></td>
                                <td><NavLink to={linque2}>{horaMinuto(registo.hora_entrada)}</NavLink></td>
                                <td><NavLink to={linque2}>{horaMinuto(registo.hora_saida)}</NavLink></td>
                            </tr>
                        })}
                    </tbody>
                </table>

                <h4>Funcionários</h4>

                <input type="submit" className="button success" value="Inserir com seleccionados" onClick={this.redirectToInsert} />

                <table>
                    <tbody>
                        {

                            this.state.funcs.map((nome) => {
                                var linque = "/funcionarios/horas/" + '?func=' + nome.id + '&data=' + data_tmp + '&hora_id=0';
                                return <tr key={nome.id}>
                                    <td>
                                        <Checkbox
                                            key={'c' + nome.id}
                                            color="primary"
                                            style={{ padding: "4px" }}
                                            onChange={this.toggleCheckbox(nome.id)}
                                        />
                                    </td>
                                    <td><NavLink to={linque}>
                                        {nome.nome}
                                    </NavLink></td>
                                    <td>{this.registo_existe(nome.id)}</td>
                                </tr>
                            })

                        }
                    </tbody>
                </table>


            </div>
        )


    }

}

export default withSnackbar(Habituais);