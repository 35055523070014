import React, { Component } from 'react';
import { PostData } from '../../services/PostData';
import Select from 'react-select';

import { askForPermissioToReceiveNotifications } from '../../push-notifications.js';
import { Redirect } from 'react-router-dom';

import db from '../../db';

import { NavLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

const styles = theme => ({
  root: {
      display: 'flex',
      flexWrap: 'wrap',
  },
  formControl: {
      margin: theme.spacing.unit,
      minWidth: 120,
  },
  selectEmpty: {
      marginTop: theme.spacing.unit * 2,
  },
});

var maquinas = [];
var maquinas_select = [];
var modelos = [];

class ListaMaquinas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      maq: '*',
      resultados: [],
      motor: '',
      motor_serie: '',
      chassis: '',
      chassis_serie: '',
      horas_km: '',
      maquinaid: 0,
      mostra_intervencao: false

    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    //  console.log(event);
    if (event === null) {

      this.setState({
        mostra_intervencao: false,
        resultados: [],
        motor: '',
        motor_serie: '',
        chassis: '',
        chassis_serie: '',
        horas_km: '',
        maquinaid: 0
      });
      
    } else {
      //{ id: 1, tab_consumiveis: "abc" }
      var modeloid = 0;
      
      this.setState({
        mostra_intervencao: true
      });

      var maquinas_ = JSON.parse(localStorage.getItem('maquinas'));
      Object.entries(maquinas_).forEach(entry => {
        //  console.log(entry);
        if (entry[1].id == event.id) {
          modeloid = entry[1].modelo;
          var maquinaid= entry[1].id;

          var motormodelo= entry[1].motor_modelo;
          var motorserie= entry[1].motor_numserie;
          var chassismodelo= entry[1].chassi_modelo;
          var chassisserie= entry[1].chassis_numserie;
          var horaskm=entry[1].horas_km;

          this.setState({
            maquinaid: maquinaid,
            motor: motormodelo,
      motor_serie: motorserie,
      chassis: chassismodelo,
      chassis_serie: chassisserie,
      horas_km: horaskm
          });


        }
      });

      var res = [];

      var modelos_ = JSON.parse(localStorage.getItem('modelos'));
      Object.entries(modelos_).forEach(entry => {
        //  console.log(entry);
        if (entry[1].id == modeloid) {

      //    console.log(entry[1]);
          res = entry[1];
        }
      });


      this.setState({
        resultados: res
      });
    }
  }

  componentWillMount() {

    //  console.log('comp will mount');
    maquinas_select = [];
    var maquinas_ = JSON.parse(localStorage.getItem('maquinas'));
    Object.entries(maquinas_).forEach(entry => {
      //    console.log(entry);
      maquinas_select.push({ id: entry[1].id, label: entry[1].matricula + ' - ' + entry[1].modelotxt });
      maquinas.push({ id: entry[1].id, modelo: entry[1].modelo })
    });

    modelos = [];
    var modelos_ = JSON.parse(localStorage.getItem('modelos'));
    Object.entries(modelos_).forEach(entry => {
      //    console.log(entry);
      modelos.push(entry);
    });

    PostData('mecanica/lista_maquinas.php', this.state).then((result) => {
      let responseJson = result;
      if (responseJson.error === 0) {
        localStorage.setItem('maquinas', JSON.stringify(responseJson.maquinas));
      }

    })

    PostData('mecanica/lista_modelos.php', this.state).then((result) => {
      let responseJson = result;
      if (responseJson.error === 0) {
        localStorage.setItem('modelos', JSON.stringify(responseJson.modelos));
      }
    })

  }





  render() {


    return (
      <div className="row" id="Body">
        <p>
          Lista de Máquinas
              </p>
        <Select
          placeholder={'Máquinas...'}
          isClearable={true}
          inputProps={{
            name: 'maquinas',
            id: 'maquinas',
          }}
          onChange={this.handleChange}
          loadOptions={maquinas_select}
          options={maquinas_select}
        />


<p>Modelo Motor: {this.state.motor} </p>
<p>N Série Motor: {this.state.motor_serie} </p>
<p>Modelo Chasssis: {this.state.chassis}</p>
<p>N Série Chassis: {this.state.chassis_serie}</p>
<p>Horas/Km: {this.state.horas_km}</p>
<br></br>
<p>
{this.state.mostra_intervencao ? <NavLink to={'/mecanica/intervencao/?maq='+this.state.maquinaid}>Inserir Intervenção</NavLink> : ''}
  
  </p>


        <div dangerouslySetInnerHTML={{ __html: this.state.resultados.tab_consumiveis }} />

      </div>


    );
  }
}


export default withSnackbar(withStyles(styles)(ListaMaquinas));
