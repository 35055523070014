import Dexie from 'dexie';

const db = new Dexie('HorasDB');

db.version(1).stores({ horas: 'id,funcionario,responsavel,data,local,tipo_falta,hora_entrada,hora_saida,hora_almoco,hora_entrada_longa,hora_saida_longa,atividade,hora_maquina_inicial,hora_maquina_final,litros_combustivel,observacoes' });
db.version(2).stores({ horas: 'hora_id,funcionario,responsavel,data,local,tipo_falta,hora_entrada,hora_saida,hora_almoco,hora_entrada_longa,hora_saida_longa,atividade,hora_maquina_inicial,hora_maquina_final,litros_combustivel,observacoes' });
db.version(3).stores({ horas: 'hora_id,funcionario,responsavel,data,local,tipo_falta,hora_entrada,hora_saida,hora_almoco,hora_entrada_longa,hora_saida_longa,atividade,hora_maquina_inicial,hora_maquina_final,litros_combustivel,hectares,observacoes' });
db.version(4).stores({ horas: 'hora_id,funcionario,responsavel,data,local,tipo_falta,hora_entrada,hora_saida,hora_almoco,hora_entrada_longa,hora_saida_longa,atividade,hora_maquina_inicial,hora_maquina_final,litros_combustivel,hectares,trator,observacoes' });
db.version(5).stores({ horas: 'hora_id,funcionario,responsavel,data,local,tipo_falta,hora_entrada,hora_saida,hora_almoco,hora_entrada_longa,hora_saida_longa,atividade,hora_maquina_inicial,hora_maquina_final,litros_combustivel,hectares,trator,maquina,maq_num_horas,local_recolha,local_entrega,observacoes' });
db.version(6).stores({ horas: 'hora_id,funcionario,responsavel,data,local,tipo_falta,hora_entrada,hora_saida,hora_almoco,hora_entrada_longa,hora_saida_longa,atividade,hora_maquina_inicial,hora_maquina_final,litros_combustivel,hectares,trator,maquina,maq_num_horas,local_recolha,local_entrega,observacoes,dia_seguinte,horas_chuva'});

db.version(7).stores({
    horas: 'hora_id,funcionario,responsavel,data,local,tipo_falta,hora_entrada,hora_saida,hora_almoco,hora_entrada_longa,hora_saida_longa,atividade,hora_maquina_inicial,hora_maquina_final,litros_combustivel,hectares,trator,maquina,maq_num_horas,local_recolha,local_entrega,observacoes,dia_seguinte,horas_chuva',
    gasoleo: 'data,maq,horas_km,local,litros,obs,operador',
    deposito: 'data,dep,atividade,litros,obs'
})


export default db;