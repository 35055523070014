import React, { Component } from 'react';
import { PostData } from '../../services/PostData';

import { Redirect } from 'react-router-dom';

import db from '../../db';

import { NavLink } from 'react-router-dom';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
//import Select from '@material-ui/core/Select';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';

import DateInput from '../Helpers/dateinput';


import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

const queryString = require('query-string');


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});


var _locais = [];
var _funcionarios = [];

var operador_select = [];
var locais_select = [];
var depositos_select = [];

class InsereGasoleo extends Component {

  constructor(props) {
    super(props);

    const parsed = queryString.parse(props.location.search);
    //  console.log(parsed);

    this.state = {
      maq: parsed.maq,
      operador: 0,
deposito:0,
      litros: '',
      horas_km: '',
      local: 0,
      obs: '',
      data: Math.floor(Date.now() / 1000),
      submitbtndisabled: false

    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOperador = this.handleOperador.bind(this);
    this.handleLocal = this.handleLocal.bind(this);
    this.handleDeposito = this.handleDeposito.bind(this);

  }

  UNSAFE_componentWillMount() {

    operador_select = [];

   // var locais = [];
    var locais2 = JSON.parse(localStorage.getItem('locais'));
    Object.entries(locais2).forEach(entry => {
      //  locais[entry[1].id] = entry[1].descr;
      locais_select.push({ id: entry[1].id, label: entry[1].descr });
    });
    //  _locais = locais;

  //  var funcionarios = [];
    var funcionarios2 = JSON.parse(localStorage.getItem('funcionarios'));
    Object.entries(funcionarios2).forEach(entry => {
      // console.log(entry);
      //  funcionarios[entry[1].id] = entry[1].nome;
      operador_select.push({ id: entry[1].id, label: entry[1].nome });

    });

    var depositos = JSON.parse(localStorage.getItem('depositos'));
    Object.entries(depositos).forEach(entry => {
      // console.log(entry);
      //  funcionarios[entry[1].id] = entry[1].nome;
      depositos_select.push({ id: entry[1].id, label: entry[1].descricao });

    });




    //  _funcionarios = funcionarios;
    //console.log(operador_select);
  }

  handleChange(event) {

    //   console.log(event);
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleOperador(event) {
    if (event === null) {

      this.setState({
        operador: 0
      });
    } else {
      this.setState({
        operador: event.id
      });

    }
  }
  handleLocal(event) {
    if (event === null) {

      this.setState({
        local: 0

      });
    } else {
      this.setState({
        local: event.id
      });
    }
  }

  handleDeposito(event) {
    if (event === null) {

      this.setState({
        deposito: 0

      });
    } else {
      this.setState({
        deposito: event.id
      });
    }
  }



  handleSubmit() {
    this.setState({ submitbtndisabled: true });

    PostData('gasoleo/gasoleo_maquina.php', this.state).then((result) => {

      //  console.log('resposta');
      //  console.log(result);

      let responseJson = result;

      if (responseJson.error === 0) {
        this.props.enqueueSnackbar(responseJson.message, { variant: 'success' });
        //     console.log(responseJson);

      } else {
        if (responseJson.error < 100) {
          this.props.enqueueSnackbar(responseJson.message, { variant: 'error' });
          this.setState({ submitbtndisabled: false });
        } else {
          this.props.enqueueSnackbar('Falha de ligação, Dados guardados para envio posterior.', { variant: 'error' });
          this.setState({ submitbtndisabled: true });

          //add to db
          const entrada = {
            data: this.state.data,
            maq: this.state.maq,
            horas_km: this.state.horas_km,
            local: this.state.local,
            litros: this.state.litros,
            obs: this.state.obs,
            operador: this.state.operador,
            deposito: this.state.deposito
          }

         
          
          db.table('gasoleo').add(entrada).catch((error) => {
            //db.table('gasoleo').update(data, entrada).catch((error) => { console.log(error) });
          });

        }


      }
    }).catch((error) => {
      this.props.enqueueSnackbar(error.message, { variant: 'error' });
      this.setState({ submitbtndisabled: false });
    });
  }



  render() {


    return (
      <div className="row" id="Body">
        <h4>
          Consumo de Gasóleo
              </h4>


        <InputLabel htmlFor="operador">Manobrador</InputLabel>
        <Select
          placeholder={'Escolha...'}
          isClearable={true}
          inputProps={{
            name: 'operador',
            id: 'operador',
          }}
          onChange={this.handleOperador}
          loadOptions={operador_select}
          options={operador_select}
        />



        <br />

        <InputLabel htmlFor="local">Local</InputLabel>
        <Select
          placeholder={'Escolha...'}
          isClearable={true}
          inputProps={{
            name: 'local',
            id: 'local',
          }}
          onChange={this.handleLocal}
          loadOptions={locais_select}
          options={locais_select}
        />


        <br />


        <InputLabel htmlFor="deposito">Depósito</InputLabel>
        <Select
          placeholder={'Escolha...'}
          isClearable={true}
          inputProps={{
            name: 'deposito',
            id: 'deposito',
          }}
          onChange={this.handleDeposito}
          loadOptions={depositos_select}
          options={depositos_select}
        />


        <br />

        <FormControl>
          <TextField
            id="litros"
            label="Litros Abastecidos"
            name="litros"
            value={this.state.litros}
            onChange={this.handleChange}
            margin="normal"
            multiline={false}
            rows={3}

            rowsMax={8}
          />
        </FormControl>


        <br />

        <FormControl>
          <TextField
            id="horas_km"
            label="Km/Horas da máquina"
            name="horas_km"
            value={this.state.horas_km}
            onChange={this.handleChange}
            margin="normal"
            multiline={false}
            rows={3}

            rowsMax={8}
          />
        </FormControl>


        <br />

        <FormControl>
          <TextField
            id="obs"
            label="Observações"
            name="obs"
            value={this.state.obs}
            onChange={this.handleChange}
            margin="normal"
            multiline={true}
            rows={3}

            rowsMax={8}
          />
        </FormControl>


        <br />





        <button onClick={this.handleSubmit} disabled={this.state.submitbtndisabled} >
          {this.state.submitbtndisabled ? '...' : 'Registar'}
        </button>
        <br />
        <br />
        <p>
          <NavLink to={"/gasoleo/listamaquinas"}>Voltar</NavLink>
        </p>
      </div>
    );
  }
}


export default withSnackbar(withStyles(styles)(InsereGasoleo));
