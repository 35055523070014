import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import './Home.css';

class Home extends Component {
  


  render() {

    if (localStorage.getItem('userData')) {
      return (<Redirect to={'/Dashboard'} />)
    } 
    
    return (
            <div>
           
            <p>Fazer login</p>
    </div>
    );
  }
}

export default Home;