import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { PostData } from '../../services/PostData';
import { NavLink } from 'react-router-dom';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



//import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Input from '@material-ui/core/Input';
//import OutlinedInput from '@material-ui/core/OutlinedInput';
//import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { SelectBase as SearchSelect } from 'react-select';

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DateInput from '../Helpers/dateinput';
import { withSnackbar } from 'notistack';



import db from '../../db';
//import { Link } from '@material-ui/core';

//import Modal from '@material-ui/core/Modal';
const queryString = require('query-string');

var _locais = [];
var _tarefas = [];
var _locais_ordem = [];
var _tarefas_ordem = [];
var _ots = [];

var obs_txt = "";
var obs_obr = false;

var maquinas_select = [];

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});

var is_admin = 0;

class Horas extends Component {


    constructor(props) {

        const parsed = queryString.parse(props.location.search);
        //  console.log(parsed);

        //var data_tmp = new Date().toISOString().slice(0,10);
        var data_tmp = parsed.data;


        if (parsed.fromadmin === "1") {
            is_admin = 1;
        }



        super(props);
        //  console.log(props);
        this.state = {
            hora_edit: parsed.hora_id,
            funcionario: parsed.func,
            multipleFuncs: parsed.funcs,
            data: data_tmp,
            hora_entrada: '',
            hora_saida: '',
            data_longa: new Date(),
            hora_entrada_longa: '',
            hora_saida_longa: '',
            obs: '',
            hora_almoco: 1,
            tipo_falta: 1,
            funcionario_txt: '',
            hora_id: 0,
            local: 0,
            local_txt: 0,
            atividade: 0,
            atividade_txt: '',
            hora_maquina_inicial: '',
            hora_maquina_final: '',
            litros_combustivel: 0,
            show_maquinas: 0,
            show_combustivel: 0,
            show_hectares: 0,
            hectares: '',
            trator: 0,
            show_trator: 0,
            maquina: '0',
            maq_num_horas: 0,
            local_recolha: '',
            local_entrega: '',
            show_maq_num_horas: 0,
            show_maq_locais: 0,
            ot: 0,
            from_admin: 0,
            dia_seguinte: 0,
            horas_chuva: false,
            submitbtndisabled: true

        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleData = this.handleData.bind(this);
        this.handleEntrada = this.handleEntrada.bind(this);
        this.handleSaida = this.handleSaida.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTarefa = this.handleTarefa.bind(this);
        this.adicionaDB = this.adicionaDB.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.detalhes = this.detalhes.bind(this);
        this.handleObs = this.handleObs.bind(this);
        this.handleOT = this.handleOT.bind(this);


    }

    handleChange(event) {

        //  console.log(event);
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }



    handleCheck = name => event => {
        //  console.log(name);
        // console.log(event);
        this.setState({ [name]: event.target.checked });
    };

    handleSelect(event) {

        //     console.log(event);
        this.setState({
            funcionario: event.value
        });
    }

    handleData(event) {
        //      console.log(event);
        var d = event.toISOString().slice(0, 10);
        this.setState({
            data_longa: event,
            data: d
        });
    }

    handleOT(event) {
        //    console.log(event);
        this.setState({
            ot: event.target.value
        });
    }


    handleEntrada(event) {
        //     console.log(event);
        var h = event.getHours();
        var m = event.getMinutes();
        var hm = h + ':' + m;
        this.setState({
            hora_entrada_longa: event,
            hora_entrada: hm
        });
    }
    handleSaida(event) {
        //      console.log(event);
        var h = event.getHours();
        var m = event.getMinutes();
        var hm = h + ':' + m;
        this.setState({
            hora_saida_longa: event,
            hora_saida: hm
        });
    }

    handleTarefa(event) {
        //  console.log(event);


        //  ver no state tarefas se é para mostrar cenas de acordo com id
        let index = event.target.selectedIndex;

        let el = event.target.value;
        let dados = _tarefas_ordem[el];

        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (el > 0) {


            this.setState({
                // show_combustivel: dados.litros_combustivel,
                //   show_hectares: dados.hectares,
                show_hectares: 1,
                //   show_maquinas: dados.horas_maquina,

                //   show_trator: dados.trator,
                // show_trator: 1,
                //  show_maq_locais: dados.maquina_locais,
                //  show_maq_num_horas: dados.maquina_num_horas,
                show_maq_num_horas: 1,
                submitbtndisabled: false,
                [name]: value

            });

            //   obs_txt = dados.obs;
            //   obs_obr = ((dados.obs.length) > 1);


        } else {
            this.setState({
                show_combustivel: 0,
                show_hectares: 0,
                show_maquinas: 0,
                show_trator: 0,
                show_maq_locais: 0,
                show_maq_num_horas: 0,
                submitbtndisabled: true,
                [name]: value
            });
            obs_txt = "";
            obs_obr = false;
        }


    }

    handleObs(event) {

        //   console.log(event.target.value);
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (value.length > 3) {
            this.setState({
                submitbtndisabled: false
            });
        } else {
            this.setState({
                submitbtndisabled: true
            });
        }
    }

    adicionaDB(enviado) {

        //    console.log(enviado);

        let id = enviado;
        if (id === 0) {
            id = (-1) * Date.now();
        }
        if (enviado < 0) {
            //   id = (-1) * enviado;
        }

        //   console.log(id);

        let u = JSON.parse(localStorage.getItem('userData'));
        let responsavel = u.userid;

        const hora = {


            hora_id: id,
            hora_maquina_final: this.state.hora_maquina_final,
            responsavel: responsavel,
            hora_maquina_inicial: this.state.hora_maquina_inicial,
            hora_saida: this.state.hora_saida,
            hora_saida_longa: this.state.hora_saida_longa,
            funcionario: this.state.funcionario,
            data: this.state.data,
            local: this.state.local,
            observacoes: this.state.obs,
            tipo_falta: this.state.tipo_falta,
            litros_combustivel: this.state.litros_combustivel,
            atividade: this.state.atividade,
            hora_entrada: this.state.hora_entrada,
            hora_entrada_longa: this.state.hora_entrada_longa,
            hora_almoco: this.state.hora_almoco,
            hectares: this.state.hectares,
            trator: this.state.trator,
            maquina: this.state.maquina,
            maq_num_horas: this.state.maq_num_horas,
            local_recolha: this.state.local_recolha,
            local_entrega: this.state.local_entrega,
            horas_chuva: this.state.horas_chuva,
            dia_seguinte: this.state.dia_seguinte,
            ot: this.state.ot
        };
        //   console.log(hora);
        //  console.log(enviado);
        //  if (enviado >= 0) {

        //      db.table('horas').add(hora);
        //  } else {

        db.table('horas').add(hora).catch((error) => {
            db.table('horas').update(id, hora).catch((error) => { console.log(error) });
        });

        //    let ide=parseInt((-1)*enviado);
        //            db.table('horas').update(id, hora).catch((error) => { console.log(error) });
        //  }
    }

    async handleSubmit(event) {
        //  alert('submitting: ' + JSON.stringify(this.state));
        event.preventDefault();
        this.setState({ submitbtndisabled: true });

        if (this.state.funcionario == 'multiple') {
            //  console.log('teste de multiplos');

            var funcs = this.state.multipleFuncs;
            var funcs2 = funcs.split('|');


            let promises = [];
            let i;
            for (i = 0; i < funcs2.length; i++) {
                //    console.log(lista[i]);
                var lista = this.state;
                lista.funcionario = funcs2[i];

                //   console.log('id a enviar:' + lista[i].hora_id);
                promises.push(PostData('funcionarios/registo_horas.php', lista));
            }


            try {
                //   console.log(promises);
                const results = await Promise.all(promises);
                console.log("All done", results);

                var erros = 0;
                var sucessos = 0;
                results.forEach(element => {
                    console.log(element);
                    if (element.error === 0) {
                        sucessos++;
                    } else {
                        erros++;
                    }
                });
                var texto = '';
                if (sucessos > 0) {
                    texto = 'Registo com sucesso: ' + sucessos + '. ';
                }
                if (erros > 0) {
                    texto += 'Registos com erro: ' + erros + '. ';
                }

                texto += 'Clique em "Obter dados do servidor" na listagem de funcionários para obter os dados atualizados.';

                var variante = 'success';
                if (erros > 0) {
                    if (sucessos === 0) {
                        variante = 'error';
                    } else {
                        variante = 'warning';
                    }
                }

                this.props.enqueueSnackbar(texto, { variant: variante });
                this.setState({ submitbtndisabled: false });


            }
            catch (e) {
                this.props.enqueueSnackbar('Ocorreu um erro ao enviar. Por favor tentar adicionar individualmente as horas.', { variant: 'error' });

            }



            //this.setState({submitbtndisabled: false});
        } else {
            PostData('funcionarios/registo_horas.php', this.state).then((result) => {

                //  console.log('resposta');
                //  console.log(result);

                let responseJson = result;

                if (responseJson.error === 0) {
                    this.props.enqueueSnackbar(responseJson.message, { variant: 'success' });
                    //     console.log(responseJson);
                    if (responseJson.insert_id > 0) {
                        this.adicionaDB(responseJson.insert_id);
                        this.setState({ hora_id: responseJson.insert_id });
                    } else {
                        this.adicionaDB(this.state.hora_id);
                    }


                } else {
                    //console.log('dentro do falha de ligacao');
                    if (responseJson.error === 100) {
                        this.props.enqueueSnackbar('Sem ligação. Os dados serão enviados mais tarde.', { variant: 'warning' });



                        if (this.state.hora_id > 0) {
                            this.adicionaDB((-1) * this.state.hora_id);
                        }

                        else {


                            this.adicionaDB(this.state.hora_id);
                        }

                    }
                    else {
                        this.props.enqueueSnackbar('Ocorreu um erro: ' + responseJson.message, { variant: 'error' });
                    }
                }

                this.setState({ submitbtndisabled: false });

            }).catch((error) => {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
                this.setState({ submitbtndisabled: false });
            });
        }







    }


    componentWillMount() {

        db.close();
        db.open();

        this.setState({ from_admin: is_admin });

        var locais = [];
        var locais2 = JSON.parse(localStorage.getItem('locais'));
        Object.entries(locais2).forEach(entry => {
            locais[entry[1].id] = entry[1].descr;
        });
        _locais = locais;


        var tarefas = [];
        var tarefas2 = JSON.parse(localStorage.getItem('tarefas'));
        Object.entries(tarefas2).forEach(entry => {
            tarefas[entry[1].id] = entry[1];
            _tarefas_ordem[entry[1].id] = entry[1];

            //    maquinas_select.push({ id: entry[1].id, label: entry[1].matricula + ' - ' + entry[1].modelotxt });

        });

        _ots = JSON.parse(localStorage.getItem('ot'));

        var obj2 = tarefas;

        obj2.sort((a, b) => {
            if (a.descr === b.descr) {
                if (a.descr === b.descr) return 0;
                return (a.descr < b.descr ? -1 : 1);
            }
            if (a.descr < b.descr) {
                return -1;
            } else {
                return 1;
            }
        });

        /*
        var obj = tarefas;
        obj.sort((a,b) => {
            return a.localeCompare(b);
        });
*/

        _tarefas = obj2;
        //     console.log(_tarefas);
        //    console.log(_tarefas_ordem);

        maquinas_select = [];
        var maquinas_ = JSON.parse(localStorage.getItem('maquinas'));

        var maquinas = [];
        Object.entries(maquinas_).forEach(entry => {

            var txt = entry[1].matricula + ' - ' + entry[1].modelotxt;
            maquinas[entry[1].id] = entry[1].matricula + ' - ' + entry[1].modelotxt;
            maquinas_select[entry[1].id] = entry[1];
        });

        var obj3 = maquinas_select;

        obj3.sort((a, b) => {
            if (a.matricula === b.matricula) {
                if (a.matricula === b.matricula) return 0;
                return (a.matricula < b.matricula ? -1 : 1);
            }
            if (a.matricula < b.matricula) {
                return -1;
            } else {
                return 1;
            }
        });

        maquinas_select = obj3;

      //  console.log(maquinas_select);

        PostData('mecanica/lista_maquinas_n.php', this.state).then((result) => {
            let responseJson = result;
            if (responseJson.error === 0) {
                localStorage.setItem('maquinas', JSON.stringify(responseJson.maquinas));
            }

        })


        let ide = parseInt(this.state.hora_edit, 10);

        if (ide === 0) {

        } else {
            this.setState({ hora_id: ide });

            /*if hora_id != 0 set edit */
            db.table('horas').get(ide).then((item) => {

                //  console.log(item);

                var ent = (this.state.data + ' ' + item.hora_entrada).split(/[- :]/);

                let he = new Date(ent[0], ent[1] - 1, ent[2], ent[3], ent[4]);
                //  console.log(he);
                if (he instanceof Date && !isNaN(he)) {
                } else {
                    he = '';
                }
                var said = (this.state.data + ' ' + item.hora_saida).split(/[- :]/);

                let hs = new Date(said[0], said[1] - 1, said[2], said[3], said[4]);
                //  console.log(hs);
                if (hs instanceof Date && !isNaN(hs)) {

                } else {
                    hs = '';
                }


                this.setState({ hora_entrada_longa: he });
                this.setState({ hora_saida_longa: hs });
                this.setState({ hora_entrada: item.hora_entrada });
                this.setState({ hora_saida: item.hora_saida });
                this.setState({ funcionario: item.funcionario });
                this.setState({ obs: item.observacoes });
                this.setState({ hora_almoco: item.hora_almoco });
                this.setState({ tipo_falta: item.tipo_falta });
                this.setState({ local: item.local });
                this.setState({ atividade: item.atividade });
                this.setState({ hora_maquina_inicial: item.hora_maquina_inicial });
                this.setState({ hora_maquina_final: item.hora_maquina_final });
                this.setState({ litros_combustivel: item.litros_combustivel });
                this.setState({ hectares: item.hectares });
                this.setState({ trator: item.trator });
                this.setState({ maquina: item.maquina });
                this.setState({ maq_num_horas: item.maq_num_horas });
                this.setState({ local_recolha: item.local_recolha });
                this.setState({ local_entrega: item.local_entrega });
                this.setState({ dia_seguinte: item.dia_seguinte });
                this.setState({ ot: item.ot });

                if (item.horas_chuva === "1") {
                    this.setState({ horas_chuva: true });
                }

                let dados = _tarefas_ordem[item.atividade];

                if (item.atividade > 0) {


                    this.setState({
                        show_combustivel: dados.litros_combustivel,
                        show_hectares: dados.hectares,
                        show_maquinas: dados.horas_maquina,
                        show_trator: dados.trator,
                        show_maq_locais: dados.maquina_locais,
                        show_maq_num_horas: dados.maquina_num_horas
                    });

                    obs_txt = dados.obs;
                    obs_obr = ((dados.obs.length) > 1);
                    this.setState({ submitbtndisabled: false });

                } else {
                    if ((item.observacoes.length) > 1) {
                        this.setState({ submitbtndisabled: false });
                    }
                }




                //    console.log(this.state);


            }).catch(function (error) { console.log(error) });

        }

        //     console.log(this.state);

    };


    detalhes() {
        if (this.state.funcionario === 'multiple') {
            return 'Vários - Inserção múltipla';

        } else {
            var nome = null;
            var teste = JSON.parse(localStorage.getItem('habituais'));

            Object.entries(teste).forEach(entry => {
                //    console.log(entry[1]);
                var a = (parseInt(this.state.funcionario));
                var b = (parseInt(entry[1].id));
                //  console.log(a);
                //   console.log(b);
                //  console.log(a==b);
                //  console.log(a===b);
                if (a === b) {
                    nome = entry[1].nome;
                }

            });
            if (nome) {
                return nome;
            } else {
                return 'Desconhecido';
            }

        }
    }

    render() {


        if (localStorage.getItem('userData')) {
        } else {
            return (<Redirect to={'/login'} />)
        }



        return (
            <div className="row" id="Body">
                <form onSubmit={this.handleSubmit}>
                    <h4>Registo de Horas</h4>
                    <p>{this.state.data}, {this.detalhes()}</p>



                    <FormControl>
                        <InputLabel htmlFor="local">Local</InputLabel>
                        <Select
                            value={this.state.local}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'local',
                                id: 'local',
                            }}
                        >
                            <MenuItem key="0" value="0">
                                <em>Escolha</em>
                            </MenuItem>
                            {_locais.map((local, id) =>
                                <MenuItem key={id} value={id}>{local}</MenuItem>
                            )};
                            <MenuItem key="-1" value="-1">
                                Outro (Prencher Obs)
                            </MenuItem>

                        </Select>
                    </FormControl>



                    <br />


                    <FormControl>
                        <InputLabel htmlFor="atividade">Tarefa</InputLabel>
                        <Select
                            value={this.state.atividade}
                            onChange={this.handleTarefa}
                            inputProps={{
                                name: 'atividade',
                                id: 'atividade',
                            }}
                        >
                            <MenuItem value="0">
                                <em>Escolha</em>
                            </MenuItem>
                            {_tarefas.map((tarefa, id) =>
                                <MenuItem key={id} value={tarefa.id} obs={tarefa.obs} horas_maquina={tarefa.horas_maquina}
                                    litros_combustivel={tarefa.litros_combustivel} >{tarefa.descr}</MenuItem>

                            )};
                            <MenuItem key="-1" value="-1">
                                Outra (Preencher Obs)
                            </MenuItem>

                        </Select>
                    </FormControl>



                    <br />

                    <FormControl>
                        <InputLabel htmlFor="ot">Ordem de Trabalho</InputLabel>
                        <Select
                            value={this.state.ot}
                            onChange={this.handleOT}
                            inputProps={{
                                name: 'ot',
                                id: 'ot',
                            }}
                        >
                            <MenuItem key="0" value="0">
                                Nenhuma
                            </MenuItem>
                            {_ots.map((ot, id) =>
                                <MenuItem key={id} value={ot.id} obs={ot.obs} >{ot.descr}</MenuItem>

                            )};


                        </Select>
                    </FormControl>



                    <br />





                    <div className={this.state.show_maq_num_horas == 0 ? '' : ''}>
                        <FormControl>
                            <InputLabel htmlFor="maquina">Máquina</InputLabel>
                            <Select
                                value={this.state.maquina}

                                placeholder={'Máquinas...'}
                                isClearable={true}
                                inputProps={{
                                    name: 'maquina',
                                    id: 'maquina',
                                }}
                                onChange={this.handleChange}

                            >
                                <MenuItem value="0">
                                    <em>Escolha / Nenhuma</em>
                                </MenuItem>
                                {maquinas_select.map((viatura, id) =>
                                    <MenuItem key={viatura.id} value={viatura.id}>{viatura.matricula} - {viatura.modelotxt}</MenuItem>

                                )};
                                <MenuItem key="-1" value="-1">
                                    Outra (Preencher Obs)
                                </MenuItem>


                            </Select>
                        </FormControl>
                        <br />


                    </div>

                    <div className={this.state.show_maq_num_horas == 0 ? '' : ''}>

                        <FormControl>
                            <TextField
                                id="maq_num_horas"
                                label="Máq - Num Horas / Km"
                                name="maq_num_horas"

                                value={this.state.maq_num_horas}
                                onChange={this.handleChange}
                                margin="normal"
                            />
                        </FormControl>
                        <br />


                    </div>


                    <div className={this.state.show_hectares == 0 ? '' : ''}>
                        <FormControl>
                            <TextField
                                id="hectares"
                                label="Área (M/Ha/Km)"
                                name="hectares"

                                value={this.state.hectares}
                                onChange={this.handleChange}
                                margin="normal"
                            />
                        </FormControl>
                        <br />
                    </div>



                    <FormControl>
                        <InputLabel htmlFor="falta">Presença/Falta</InputLabel>
                        <Select
                            value={this.state.tipo_falta}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'tipo_falta',
                                id: 'tipo_falta',
                            }}
                        >
                            <MenuItem value="1">Presença</MenuItem>
                            <MenuItem value="3">Falta</MenuItem>
                            <MenuItem value="4">Folga</MenuItem>
                            <MenuItem value="7">Férias</MenuItem>
                            <MenuItem value="11">Chuva</MenuItem>

                        </Select>
                    </FormControl>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.horas_chuva}
                                onChange={this.handleCheck('horas_chuva')}
                                value={this.state.horas_chuva}
                                color="primary"
                            />
                        }
                        label="Chuva Horas restantes"
                    />

                    <br />
                    <br />
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <label>Hora Entrada</label>
                                </td><td> <DatePicker
                                    customInput={<DateInput />}
                                    selected={this.state.hora_entrada_longa}
                                    onChange={this.handleEntrada}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    timeCaption="Hora"

                                />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Hora Saída</label>
                                </td>
                                <td>
                                    <DatePicker
                                        customInput={<DateInput />}
                                        selected={this.state.hora_saida_longa}
                                        onChange={this.handleSaida}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        timeCaption="Hora"

                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <br />



                    <FormControl>
                        <InputLabel htmlFor="horaalmoco">Fez hora de almoço</InputLabel>
                        <Select
                            value={this.state.hora_almoco}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'hora_almoco',
                                id: 'hora_almoco',
                            }}
                        >
                            <MenuItem value="1">Sim&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>

                            <MenuItem value="0">Não&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>



                        </Select>
                    </FormControl>

                    <FormControl>
                        <InputLabel htmlFor="dia_seguinte">Saída no dia seguinte</InputLabel>
                        <Select
                            value={this.state.dia_seguinte}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'dia_seguinte',
                                id: 'dia_seguinte',
                            }}
                        >
                            <MenuItem value="0">Não&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>
                            <MenuItem value="1">Sim&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>


                        </Select>
                    </FormControl>

                    <br />


                    <FormControl>
                        <TextField
                            id="obs"
                            label="Observações"
                            name="obs"
                            placeholder={obs_txt}
                            required={obs_obr}

                            value={this.state.obs}
                            onChange={this.handleObs}
                            margin="normal"
                            multiline={true}
                            rows={3}

                            rowsMax={8}
                        />
                    </FormControl>


                    <br />


                    <button onClick={this.handleSubmit} disabled={this.state.submitbtndisabled} >
                        {this.state.submitbtndisabled ? '...' : 'Registar'}
                    </button>

                </form>

                <br /><br />

                {is_admin === 1 ? <NavLink to={"/admin/horas"}>Voltar</NavLink> : <NavLink to={"/funcionarios/habituais"}>Voltar</NavLink>}

                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </div>


        );
    }

}

export default withSnackbar(withStyles(styles)(Horas));