import React, { Component } from 'react';
import Routes from './routes';

import { SnackbarProvider } from 'notistack';
import Button from '@material-ui/core/Button';
import './index.css';



class App extends Component {
    
    constructor(){
    super();
    this.state={
      appName: "Herdade das Amêndoas Doces",
      home: false
    }
  }
  

  render() {
    //   console.log(this.state);
   return (
  
        <SnackbarProvider maxSnack={2}
    action={[
        <Button color="default" size="small">
            {'X'}
        </Button>
    ]}
>
       <Routes name={this.state.appName}>
      
    
        </Routes>
</SnackbarProvider>

     
    );
  }
}

export default App;
