import React, { Component } from 'react';
import { PostData } from '../../services/PostData';
import Select from 'react-select';

import { askForPermissioToReceiveNotifications } from '../../push-notifications.js';
import { Redirect } from 'react-router-dom';

import db from '../../db';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';


import { NavLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';


const queryString = require('query-string');


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class EntradaEncomendas extends Component {

  constructor(props) {

    const parsed = queryString.parse(props.location.search);
    //  console.log(parsed);

    //var data_tmp = new Date().toISOString().slice(0,10);
    var enc_tmp = parsed.enc;



    super(props);
    this.state = {
      enc: enc_tmp,
      linhas: [],
      prods: [],
      obs: '',
      maq:0,
      horaskm:0,
      submitbtndisabled: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleObs= this.handleObs.bind(this);

  }

  handleChange(event) {


    var v = (event.target.id);
    var value = parseFloat(event.target.value.replace(",", "."));

    console.log(event.target.value.endsWith('.'));
    console.log(value);

    if(event.target.value.replace(",", ".").endsWith('.')){
      this.setState({
        [v]: event.target.value.replace(",", ".")
      });
    } else {
      if (value > this.state.prods[v]) {

        value = this.state.prods[v];
      }
      if (value < 0) {
        value = 0;
      }
      if (isNaN(value)) {
        value = 0;
      }
  
      this.setState({
        [v]: value
      });

    }
 
  }

  handleObs(event) {

    //   console.log(event.target.value);
     const target = event.target;
     const value = target.value;
     const name = target.name;

     this.setState({
         obs: value
     });

    
 }


  UNSAFE_componentWillMount() {

    this.props.enqueueSnackbar('A obter informação do servidor', { variant: 'success' });


    PostData('logistica/info_encomenda.php', this.state).then((result) => {
      let responseJson = result;
      if (responseJson.error === 0) {
        this.props.enqueueSnackbar(responseJson.message, { variant: 'success' });
        this.setState({ linhas: responseJson.produtos }, () => {
          //criar prods com [id]=quant
          var prods1 = [];
          this.state.linhas.map((linha) => {
            this.setState({ [linha.id]: 0 });
            prods1[linha.id] = parseFloat(linha.falta);
          });
          this.setState({ prods: prods1 });
        })


      } else {
        this.props.enqueueSnackbar(responseJson.message, { variant: 'error' });

      }

    })

  }



  async  handleSubmit(event) {
    //  alert('submitting: ' + JSON.stringify(this.state));
    event.preventDefault();
    this.setState({ submitbtndisabled: true });


    PostData('logistica/ent_encomenda.php', this.state).then((result) => {
      let responseJson = result;
      if (responseJson.error === 0) {
        this.props.enqueueSnackbar(result.message, { variant: 'success' });
      } else {
        this.props.enqueueSnackbar(result.message, { variant: 'error' });
      }
    })
  }


  render() {


    return (
      <div className="row" id="Body">
        <h4>Entrada de Encomenda</h4>


        <form onSubmit={this.handleSubmit}>
          <table border="0">
            <tbody>
              {this.state.linhas.map((linha) => {
                //     console.log(linha);
                return <React.Fragment key={linha.id}>
                  <tr><td>{linha.material}</td><td>{linha.descricao}</td></tr>
                  <tr><td>{linha.data_prevista}</td><td>Obra: {linha.obra}</td></tr>
                  <tr><td>Total: {linha.falta}</td>
                    <td >
                      Quant Recebida:&nbsp;&nbsp;&nbsp;
                        <input type="text" style={{ width: 50, verticalAlign: 'middle' }} id={linha.id} value={this.state[linha.id]} onChange={this.handleChange}></input>
                    </td>
                  </tr>
                  <tr><td>&nbsp;</td></tr>
                </React.Fragment>


              })
              }
            </tbody>
          </table>

          <FormControl>
                        <TextField
                            id="obs"
                            label="Observações - Indique número de guia/fatura"
                            name="obs"
              
                            value={this.state.obs}
                            onChange={this.handleObs}
                            margin="normal"
                            multiline={true}
                            rows={3}

                            rowsMax={8}
                        />
                    </FormControl>
                    <br /><br />
          <button onClick={this.handleSubmit} disabled={this.state.submitbtndisabled} >
            {this.state.submitbtndisabled ? '...' : 'Dar Entrada'}
          </button>
        </form>
        <br /><br />
        <NavLink to={"/encomendas/lista"}>Voltar</NavLink>
      </div>

    );
  }
}


export default withSnackbar(withStyles(styles)(EntradaEncomendas));
