export function horaMinuto(time) {
 //   console.log(time);
    var date = new Date("July 21, 1983 " + time);

    if (date && time) {
     //   console.log(date);

        var h = date.getHours();
        var m = date.getMinutes();

        if (h < 10) {
            h = "0" + h;
        }
        if (m < 10) {
            m = "00";
        }
        return h + ":" + m;
    } else {
        return "";
    }
}
