import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { PostData } from '../../services/PostData';
//import Select from 'react-select';
import { NavLink } from 'react-router-dom';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from '@material-ui/core/Checkbox';
//import moment from 'moment';

import { withSnackbar } from 'notistack';

import db from '../../db';

import { horaMinuto } from '../Helpers/helpers';
import DateInput from '../Helpers/dateinput';
//import { resolve } from 'path';

//import Modal from '@material-ui/core/Modal';



class Ordens extends Component {



    constructor(props) {
        super(props);
        this.state = {
            texto: '',
         
        };
    };

    

    componentWillMount() {

            console.log(this.state);

        PostData('funcionarios/lista_ordens_html.php', this.state).then((result) => {
            let responseJson = result;
            //       console.log(responseJson);
            if (responseJson.error === 0) {


                this.setState({
                    texto:JSON.stringify(responseJson.texto)
                })

               
            }
        });

    }

    render() {

      

        if (localStorage.getItem('userData')) {
        } else {
            return (<Redirect to={'/login'} />);
        }

        return (
            <div className="row" id="Body">
             
             <div dangerouslySetInnerHTML={{ __html: this.state.texto }}></div>
             
            </div>
        )


    }

}


export default withSnackbar(Ordens);