import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { PostData } from '../../services/PostData';

import './Login.css';

import { withSnackbar } from 'notistack';


class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            redirectToReferrer: false
        };

        this.login = this.login.bind(this);
        this.horas = this.horas.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    horas() {
        console.log('pediu habituais');
        PostData('funcionarios/lista_habituais.php', this.state).then((result) => {
            let responseJson = result;
            console.log(responseJson);
            if (responseJson.error === 0) {
                localStorage.setItem('habituais', JSON.stringify(responseJson.funcionarios));
            }
        });
    }

    login() {
        if (this.state.username && this.state.password) {
            PostData('login/login.php', this.state).then((result) => {
                let responseJson = result;
                //      console.log(responseJson);
                if (responseJson.error === 0) {
                    localStorage.setItem('userData', JSON.stringify(responseJson.data));
                    localStorage.setItem('modulos', JSON.stringify(responseJson.modulos));

                    //load caches 
                    PostData('locais/locais.php', this.state).then((result) => {
                        let responseJson = result;
                        //   console.log(responseJson);
                        if (responseJson.error === 0) {
                            localStorage.setItem('locais', JSON.stringify(responseJson.locais));
                        }
                    });


                    //horas caches
                    if (responseJson.modulos.includes('registo_horas')) {
                        PostData('funcionarios/lista_habituais.php', this.state).then((result) => {
                            let responseJson = result;
                            //       console.log(responseJson);
                            if (responseJson.error === 0) {
                                localStorage.setItem('habituais', JSON.stringify(responseJson.funcionarios));
                            }
                        });
                        PostData('funcionarios/tarefas_habituais.php', this.state).then((result) => {
                            let responseJson = result;
                            //   console.log(responseJson);
                            if (responseJson.error === 0) {
                                localStorage.setItem('tarefas', JSON.stringify(responseJson.tarefas));
                            }
                        });

                    }

                    //horas_admin cache
                    if (responseJson.modulos.includes('horas_admin')) {

                        PostData('admin/pre_carregamento.php', this.state).then((result) => {

                            let responseJson = result;

                            if (responseJson.error === 0) {
                                localStorage.setItem('tarefas', JSON.stringify(responseJson.tarefas));
                                localStorage.setItem('locais', JSON.stringify(responseJson.locais));
                                localStorage.setItem('habituais', JSON.stringify(responseJson.funcs));
                                localStorage.setItem('admin_encs', JSON.stringify(responseJson.encs));
                            }
                        })
                    }

                    //mecanica caches
                    if (responseJson.modulos.includes('mecanica')) {

                        PostData('mecanica/lista_maquinas.php', this.state).then((result) => {
                            let responseJson = result;
                            if (responseJson.error === 0) {
                                localStorage.setItem('maquinas', JSON.stringify(responseJson.maquinas));
                            }
                        })

                        PostData('mecanica/lista_modelos.php', this.state).then((result) => {
                            let responseJson = result;
                            if (responseJson.error === 0) {
                                localStorage.setItem('modelos', JSON.stringify(responseJson.modelos));
                            }
                        })
                        
                    }

                    if (responseJson.modulos.includes('gasoleo')) {

                        PostData('mecanica/lista_maquinas.php', this.state).then((result) => {
                            let responseJson = result;
                            if (responseJson.error === 0) {
                                localStorage.setItem('maquinas', JSON.stringify(responseJson.maquinas));
                            }
                        })
                        
                        PostData('gasoleo/lista_depositos.php', this.state).then((result) => {
                            let responseJson = result;
                            if (responseJson.error === 0) {
                                localStorage.setItem('depositos', JSON.stringify(responseJson.depositos));
                            }
                        })

                        PostData('funcionarios/lista_funcionarios.php', this.state).then((result) => {
                            let responseJson = result;
                            if (responseJson.error === 0) {
                                localStorage.setItem('funcionarios', JSON.stringify(responseJson.funcionarios));
                            }
                        })

                        
                    }


                    //   this.setState({redirectToReferrer: true});
                    this.props.history.push("/dashboard");

                } else {
                    this.props.enqueueSnackbar(responseJson.message, { variant: 'error' });
                }

            });
        }

    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {

        if (this.state.redirectToReferrer) {
            return (<Redirect to={'/home'} />)
        }

        if (localStorage.getItem('userData')) {
            return (<Redirect to={'/Dashboard'} />)
        }

        return (
            <div className="row" id="Body">
                <div className="medium-5 columns left">
                    <h4>Login</h4>
                    <p><label>Username </label>
                        <input type="text" name="username" placeholder="Username" onChange={this.onChange} /></p>
                    <p><label>Password </label>
                        <input type="password" name="password" placeholder="Password" onChange={this.onChange} /></p>
                    <input type="submit" className="button success" value="Login" onClick={this.login} />

                </div>
            </div>
        );
    }

    /*
     login(username, password) {
     const requestOptions = {
     method: 'POST',
     headers: { 'Content-Type': 'application/json' },
     body: JSON.stringify({ username, password })
     };
     
     return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
     .then(handleResponse)
     .then(user => {
     // login successful if there's a jwt token in the response
     if (user.token) {
     // store user details and jwt token in local storage to keep user logged in between page refreshes
     localStorage.setItem('user', JSON.stringify(user));
     }
     
     return user;
     });
     }
     
     logout() {
     // remove user from local storage to log user out
     localStorage.removeItem('user');
     }
     
     handleResponse(response) {
     return response.text().then(text => {
     const data = text && JSON.parse(text);
     if (!response.ok) {
     if (response.status === 401) {
     // auto logout if 401 response returned from api
     logout();
     location.reload(true);
     }
     
     const error = (data && data.message) || response.statusText;
     return Promise.reject(error);
     }
     
     return data;
     });
     }
     
     render() {
     return (
     <div>
     login
     </div>
     );
     }
     
     */
}

export default withSnackbar(Login);