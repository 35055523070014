

export function PostData(type, userData) {

    


  //  let BaseURL = 'http://localhost/amendoas/api/';

  //   let BaseURL = 'http://192.168.1.69/amendoas/api/';

  let BaseURL = 'https://app.herdadeamendoasdoces.pt/api/';



    if (localStorage.getItem('userData')) {
        var u = JSON.parse(localStorage.getItem('userData'));
     //   console.log(u.userid);
        userData.userid = u.userid;
        userData.token = u.accesstoken;
    }

    //   console.log(userData);

    /*
     * 
     * Date.now() = timestamp para repetir chamadas
     * 
     * 
    
var config = {
  headers: {'Auhorization': 'Bearer 123456'}
};

console.log(JSON.stringify(userData));

return new Promise((resolve, reject) => {

axios.post(BaseURL + type, JSON.stringify(userData), config)
        .then((response) => response.json())
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });

});
     
*/


    return new Promise((resolve, reject) => {


        fetch(BaseURL + type, {
            method: 'POST',
            body: JSON.stringify(userData)
        }).then((response) => response.json())
            .then((res) => {

                resolve(res);
            })
            .catch((error) => {
                resolve({ error: 100, message: 'Falha de ligação, dados guardados.', userData });
                //   if (error.message === 'Failed to fetch') {
                //        resolve({ error: 200, message: "Falha de ligação" });
                //    } else {
                //       reject(error);
                //    }

            });


    });

}