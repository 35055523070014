import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  }
});


class Navbar extends Component {

  state = {
    //  auth: true,
    anchorEl: null,
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });

  };

  render() {

    const { classes } = this.props;
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);


    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              aria-owns={open ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={this.handleClose}
            >



              {localStorage.getItem('userData') ?
                (
                  <div>

                    <MenuItem onClick={this.handleClose}>
                      <NavLink to="/logout">Logout</NavLink>
                    </MenuItem>
                    <MenuItem onClick={this.handleClose}><NavLink to="/dashboard">
                      Dashboard
                    </NavLink></MenuItem>

                    {localStorage.getItem('modulos').includes("registo_horas") ? (<div>
                      <MenuItem onClick={this.handleClose}>
                        <NavLink to="/funcionarios/habituais">Funcionários</NavLink>
                      </MenuItem>
                      <MenuItem onClick={this.handleClose}>
                        <NavLink to="/funcionarios/ordens">Ordens de Trabalho</NavLink>
                      </MenuItem>
                      <MenuItem onClick={this.handleClose}>
                        <NavLink to="/funcionarios/relatorio_horas">Relatório de Erros</NavLink>
                      </MenuItem></div>
                    ) : (<div></div>)}

                    {localStorage.getItem('modulos').includes("mecanica") ? (<MenuItem onClick={this.handleClose}>
                      <NavLink to="/mecanica/listamaquinas">Lista de Máquinas</NavLink>
                    </MenuItem>) : (<div></div>)}


         


                    {localStorage.getItem('modulos').includes("gasoleo") ? (<MenuItem onClick={this.handleClose}>
                      <NavLink to="/gasoleo/listamaquinas">Gasóleo</NavLink>
                    </MenuItem>) : (<div></div>)}

                    {localStorage.getItem('modulos').includes("receber_encomendas") ? (<MenuItem onClick={this.handleClose}>
                      <NavLink to="/encomendas/lista">Receber Encomendas</NavLink>
                    </MenuItem>) : (<div></div>)}

                  </div>) : (<MenuItem onClick={this.handleClose}><NavLink to="/login">
                    login
                  </NavLink></MenuItem>

                )}

            </Menu>
            Herdade das Amêndoas Doces

          </Toolbar>

        </AppBar>

      </div>
    )
  }

}




export default withStyles(styles)(Navbar);