import React, { Component } from 'react';
import { PostData } from '../../services/PostData';

import { Redirect } from 'react-router-dom';

import db from '../../db';

import { NavLink } from 'react-router-dom';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
//import Select from 'react-select';
import TextField from '@material-ui/core/TextField';

import DateInput from '../Helpers/dateinput';


import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

const queryString = require('query-string');


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});


var _locais = [];
var _funcionarios = [];

var operador_select =[];
var locais_select =[];

class InsereDeposito extends Component {

  constructor(props) {
    super(props);

    const parsed = queryString.parse(props.location.search);
    //  console.log(parsed);

    this.state = {
      dep: parsed.dep,
   mov: 'sai',
      litros: 0,
  
      obs: '',
      data: Math.floor(Date.now() / 1000),
      submitbtndisabled : false

    };

    this.handleSubmit= this.handleSubmit.bind(this);
    this.handleChange= this.handleChange.bind(this);
    this.handleOperador= this.handleOperador.bind(this);
    this.handleLocal= this.handleLocal.bind(this);

  }


  handleChange(event) {

      console.log(event);
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleOperador(event){
    this.setState({
      operador: event.id
    });
  }
  handleLocal(event){
    this.setState({
      local: event.id
    });
  }



  handleSubmit() {
    this.setState({ submitbtndisabled: true });

    PostData('gasoleo/carregar_deposito.php', this.state).then((result) => {

      //  console.log('resposta');
      //  console.log(result);

      let responseJson = result;

      if (responseJson.error === 0) {
        this.props.enqueueSnackbar(responseJson.message, { variant: 'success' });
        //     console.log(responseJson);

      } else {
        if (responseJson.error < 100) {
          this.props.enqueueSnackbar(responseJson.message, { variant: 'error' });
          this.setState({ submitbtndisabled: false });
        } else {
          this.props.enqueueSnackbar('Falha de ligação, Dados guardados para envio posterior.', { variant: 'error' });
          this.setState({ submitbtndisabled: false });

          //add to db
          
           const entrada = {
            dep: this.state.dep,
            mov: this.state.mov,
               litros: this.state.litros,
               obs: this.state.obs,
               data: this.state.data
          }

         
          
          db.table('deposito').add(entrada).catch((error) => {
            //db.table('gasoleo').update(data, entrada).catch((error) => { console.log(error) });
          });

        }


      }
    }).catch((error) => {
      this.props.enqueueSnackbar(error.message, { variant: 'error' });
      this.setState({ submitbtndisabled: false });
    });
  }



  render() {


    return (
      <div className="row" id="Body">
        <h4>
          Movimento de Depósito
              </h4>

      
              <FormControl>
                        <InputLabel htmlFor="mov">Tipo Movimento</InputLabel>
                        <Select
                            value={this.state.mov}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'mov',
                                id: 'mov',
                            }}
                        >
                            <MenuItem value="sai">Retirar Gasóleo</MenuItem>
                            <MenuItem value="ent">Abastecer Depósito</MenuItem>
                          

                        </Select>
                    </FormControl>

        

        <br />
        <FormControl>
          <TextField
            id="litros"
            label="Litros Abastecidos"
            name="litros"
            value={this.state.litros}
            onChange={this.handleChange}
            margin="normal"
            multiline={false}
            rows={3}

            rowsMax={8}
          />
        </FormControl>


        <br />

       
        <FormControl>
          <TextField
            id="obs"
            label="Observações"
            name="obs"
            value={this.state.obs}
            onChange={this.handleChange}
            margin="normal"
            multiline={true}
            rows={3}

            rowsMax={8}
          />
        </FormControl>


        <br />





        <button onClick={this.handleSubmit} disabled={this.state.submitbtndisabled} >
          {this.state.submitbtndisabled ? '...' : 'Registar'}
        </button>
        <br />
        <br />
        <p>
          <NavLink to={"/gasoleo/listamaquinas"}>Voltar</NavLink>
        </p>
      </div>
    );
  }
}


export default withSnackbar(withStyles(styles)(InsereDeposito));
