import React from 'react';
import {BrowserRouter,  Route,  Switch} from 'react-router-dom';



import Home from '././components/Home/home';
import Login from '././components/Login/login';
import Logout from '././components/Login/logout';
import Dashboard from '././components/Dashboard/dashboard';

import NotFound from '././components/NotFound/notFound';
import Navbar from '././components/Navbar/navbar';

import Horas from '././components/Horas/horas';
import Habituais from '././components/Funcionarios/habituais';
import RelatorioHoras from '././components/Funcionarios/relatorio_horas';
import Ordens from '././components/Funcionarios/ordens_trabalho';

import ListaMaquinas from '././components/Maquinas/listamaquinas';
import InserirIntervencao from '././components/Maquinas/intervencao';

import HorasAdmin from '././components/Admin/admin_horas';

import ListaMaquinasG from '././components/Gasoleo/listamaquinasg';
import InsereGasoleo from '././components/Gasoleo/mov_gasoleo';
import InsereDeposito from '././components/Gasoleo/mov_deposito';

import Encomendas from '././components/Encomendas/encomendas';
import EntradaEncomendas from '././components/Encomendas/encomendas_entrada';

const Routes = () => (
  <BrowserRouter >
      <div>
     <Navbar/>
      <Switch>
          <Route exact path="/" component={Home}/>
        
          <Route path="/login" component={Login}/>
          <Route path="/logout" component={Logout}/>
          <Route path="/dashboard" component={Dashboard}/>
         
          <Route path="/funcionarios/horas" component={Horas}/>
          <Route path="/funcionarios/habituais" component={Habituais}/>
          <Route path="/funcionarios/relatorio_horas" component={RelatorioHoras}/>
          <Route path="/funcionarios/ordens" component={Ordens}/>
        
          <Route path="/admin/horas" component={HorasAdmin}/>
         
          <Route path="/mecanica/listamaquinas" component={ListaMaquinas}/>
          <Route path="/mecanica/intervencao" component={InserirIntervencao}/>
         
          <Route path="/gasoleo/listamaquinas" component={ListaMaquinasG}/>
          <Route path="/gasoleo/inseregasoleo" component={InsereGasoleo}/>
          <Route path="/gasoleo/inseredeposito" component={InsereDeposito}/>

          <Route path="/encomendas/lista" component={Encomendas}/>
          <Route path="/encomendas/entrada" component={EntradaEncomendas}/>
         
          <Route path="*" component={NotFound}/>
      </Switch>
      </div>
  </BrowserRouter>
);

export default Routes;