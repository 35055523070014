import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { Redirect } from 'react-router-dom';
import { PostData } from '../../services/PostData';
import { NavLink } from 'react-router-dom';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

import db from '../../db';


import {horaMinuto} from '../Helpers/helpers';

var funcs = [];
var locais = [];
var encs = [];
var tarefas = [];

var _funcionarios = [];
//var resultados = [];


class HorasAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data_longa: new Date(),
            data: new Date().toISOString().slice(0, 10),
            local: '*',
            tarefa: '*',
            enc: '*',
            func: '*',
            resultados: []

        };

        this.getHorasList = this.getHorasList.bind(this);
        this.getHorasList_ = this.getHorasList_.bind(this);
        this.handleTarefa = this.handleTarefa.bind(this);
        this.handleLocal = this.handleLocal.bind(this);
        this.handleEncs = this.handleEncs.bind(this);
        this.handleFuncs = this.handleFuncs.bind(this);
        this.handleData = this.handleData.bind(this);
    }




    UNSAFE_componentWillMount() {
tarefas=[];
       var tarefas_ = JSON.parse(localStorage.getItem('tarefas'));
       Object.entries(tarefas_).forEach(entry => {
         //  console.log(entry);
        tarefas.push( {value: entry[1].id, label: entry[1].descr });
    });
  //  console.log(tarefas);
 
  locais=[];
  var locais_ = JSON.parse(localStorage.getItem('locais'));
  Object.entries(locais_).forEach(entry => {
    //  console.log(entry);
   locais.push( {value: entry[1].id, label: entry[1].descr });
});

     

        encs = JSON.parse(localStorage.getItem('admin_encs'));
        funcs = JSON.parse(localStorage.getItem('habituais'));

        var funcionarios = [];

        var teste = JSON.parse(localStorage.getItem('habituais'));

        Object.entries(teste).forEach(entry => {
            funcionarios[entry[1].value] = entry[1].label;

        });

        _funcionarios = funcionarios;


    }

    getHorasList() {
        this.setState({
            resultados: []
        }, () => {
            this.getHorasList_();
        });
    }

    getHorasList_() {
        //  console.log(this.state);


        PostData('admin/filtro_horas.php', this.state).then((result) => {

            let responseJson = result;
            console.log(responseJson);
            if (responseJson.error !== 100) {

                if (responseJson.error > 0) {
                    this.props.enqueueSnackbar(responseJson.message, { variant: 'error' });
                } else {
                    //carrega tabela
                    db.horas.clear().then(() => {

                        for (var id in responseJson.horas) {
                            //   console.log(id);
                            //    console.log(responseJson.horas[id]);
                            let hora = {
                                hora_id: parseInt(responseJson.horas[id].id, 10),
                                hora_maquina_final: responseJson.horas[id].hora_maquina_final,
                                responsavel: responseJson.horas[id].responsavel,
                                hora_maquina_inicial: responseJson.horas[id].hora_maquina_inicial,
                                hora_saida: responseJson.horas[id].hora_saida,
                                funcionario: responseJson.horas[id].funcionario,
                                data: responseJson.horas[id].data,
                                local: responseJson.horas[id].local,
                                observacoes: responseJson.horas[id].observacoes,
                                tipo_falta: responseJson.horas[id].tipo_falta,
                                litros_combustivel: responseJson.horas[id].litros_combustivel,
                                atividade: responseJson.horas[id].atividade,
                                hora_entrada: responseJson.horas[id].hora_entrada,
                                hora_entrada_longa: new Date(),
                                hora_saida_longa: new Date(),
                                hora_almoco: responseJson.horas[id].hora_almoco,
                                hectares: responseJson.horas[id].hectares,
                                trator: responseJson.horas[id].trator_apoio,
                                maquina: responseJson.horas[id].maquina,
                                maq_num_horas: responseJson.horas[id].maq_num_horas,
                                local_entrega: responseJson.horas[id].local_entrega,
                                local_recolha: responseJson.horas[id].local_recolha,
                                dia_seguinte:responseJson.horas[id].dia_seguinte,
                                horas_chuva:responseJson.horas[id].horas_chuva
                                //Fri Mar 22 2019 11:30:00 GMT+0000 (Western European Standard Time)
                                //
                            };

                            db.table('horas').add(hora);
                        }

                    }).then(() => {
                        db.table('horas').where("data").equals(this.state.data).toArray().then((lista) => {

                            this.setState({ resultados: lista });
                            this.props.enqueueSnackbar(responseJson.message, { variant: 'success' });
                        }).catch(function (error) { console.log(error) });

                    });
                }
            }
            else {
                this.props.enqueueSnackbar(responseJson.message, { variant: 'warning' });
            }
        })
    }

    handleData(event) {
        var d = event.toISOString().slice(0, 10);
        this.setState({
            data_longa: event,
            data: d
        });

    }
    handleTarefa(event) {
        if (event === null) {
            this.setState({
                tarefa: "*"
            });
        } else {
            this.setState({
                tarefa: event.value
            });
        }

    }
    handleLocal(event) {
        if (event === null) {
            this.setState({
                local: "*"
            });
        } else {
            this.setState({
                local: event.value
            });
        }
    }
    handleEncs(event) {
        if (event === null) {
            this.setState({
                enc: "*"
            });
        } else {
            this.setState({
                enc: event.value
            });
        }
    }
    handleFuncs(event) {
        if (event === null) {
            this.setState({
                func: "*"
            });
        } else {
            this.setState({
                func: event.value
            });
        }
    }

    render() {

        var data_tmp = this.state.data;

        if (localStorage.getItem('userData')) {
        } else {
            return (<Redirect to={'/login'} />);
        }

        if (localStorage.getItem('userData')) {
        } else {
            return (<Redirect to={'/login'} />);
        }

        return (
            <div className="row" id="Body">
                <br />
                <label>Data </label>
                <DatePicker

                    dateFormat="yyyy-MM-dd"
                    selected={this.state.data_longa}
                    onChange={this.handleData}
                />

                <br />

                <Select
                    placeholder={'Tarefa...'}

                    isClearable={true}
                    inputProps={{
                        name: 'tarefa',
                        id: 'tarefa',
                    }}
                    onChange={this.handleTarefa}
                    loadOptions={tarefas}
                    options={tarefas}
                />
                <Select
                    placeholder={'Locais...'}
                    isClearable={true}
                    inputProps={{
                        name: 'local',
                        id: 'local',
                    }}
                    onChange={this.handleLocal}
                    loadOptions={locais}
                    options={locais}
                />
                <Select
                    placeholder={'Encarregados...'}
                    isClearable={true}
                    inputProps={{
                        name: 'encs',
                        id: 'encs',
                    }}
                    onChange={this.handleEncs}
                    loadOptions={encs}
                    options={encs}
                />
                <Select
                    placeholder={'Funcionários...'}
                    isClearable={true}
                    inputProps={{
                        name: 'funcs',
                        id: 'funcs',
                    }}
                    onChange={this.handleFuncs}
                    loadOptions={funcs}
                    options={funcs}
                />
                <input type="submit" className="button success" value="Obter dados do servidor" onClick={this.getHorasList} />

                <h4>Registos</h4>
                <table id="registos_dia">
                    <thead>
                        <tr><th>Funcionário</th><th>Entrada</th><th>Saída</th></tr>
                    </thead>
                    <tbody>
                        {this.state.resultados.map((registo) => {

                            //console.log(registo);

                           // registo.hora_entrada + ' - ' + registo.hora_saida;
                            var linque2 = "/funcionarios/horas/" + '?func=' + registo.funcionario + '&data=' + data_tmp + '&hora_id=' + registo.hora_id+'&fromadmin=1';

                            return <tr key={registo.id}>

                                <td><NavLink to={linque2}>{_funcionarios[registo.funcionario]}</NavLink></td>
                                <td><NavLink to={linque2}>{horaMinuto(registo.hora_entrada)}</NavLink></td>
                                <td><NavLink to={linque2}>{horaMinuto(registo.hora_saida)}</NavLink></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withSnackbar(HorasAdmin);