import React, { Component } from 'react';
import { askForPermissioToReceiveNotifications } from '../../push-notifications.js';
import {Redirect} from 'react-router-dom';

import db from '../../db';

class RelatorioHoras extends Component {

  constructor(props) {
    super(props);
    this.state = {

      horas: [],
      logs: [],
      teste:''

    };


  }



  render() {


    return (
      <div className="row" id="Body">
        <p>
          Lista de funcionários e erros de horas
              </p>
     
     </div>
    );
  }
}

export default RelatorioHoras;