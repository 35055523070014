import React, { Component } from 'react';
import { PostData } from '../../services/PostData';
import Select from 'react-select';

import { askForPermissioToReceiveNotifications } from '../../push-notifications.js';
import { Redirect } from 'react-router-dom';

import db from '../../db';

import { NavLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

var maquinas = [];
var maquinas_select = [];
var depositos_select = [];

class ListaMaquinasG extends Component {

  constructor(props) {
    super(props);
    this.state = {
      depositoid: 0,
      maquinaid: 0,
      mostra_maquina: false,
      mostra_deposito: false,
      pendentes: 0,
      submitbtndisabled: false,
      p_gas: 0,
      p_dep: 0

    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDep = this.handleDep.bind(this);
    this.enviaPendentes = this.enviaPendentes.bind(this);
    this.enviaPendentes2 = this.enviaPendentes2.bind(this);
  }

  async enviaPendentes2(lista) {
    let promises = [];
    var i;
    //  console.log(lista);

    for (i = 0; i < lista.length; i++) {

      promises.push(PostData('gasoleo/gasoleo_maquina.php', lista[i]));
    }

    try {
      const results = await Promise.all(promises);
      console.log(results);
      //console.log(results[0].userData.data);

      for (i = 0; i < results.length; i++) {
        if (results[i].error === 0) {
          db.table('gasoleo').where("data").equals(results[i].data).delete().then(() => {
            //  console.log('abc');

          }).catch((error) => {
            console.log(error);
          })
        }
      }
    }

    catch (e) {
      console.log(e);
    }
  }

  async enviaPendentes3(lista) {
    let promises = [];
    var i;
    //  console.log(lista);

    for (i = 0; i < lista.length; i++) {

      promises.push(PostData('gasoleo/carregar_deposito.php', lista[i]));
    }

    try {
      const results = await Promise.all(promises);
      console.log(results);
      //console.log(results[0].userData.data);

      for (i = 0; i < results.length; i++) {
        if (results[i].error === 0) {
          db.table('deposito').where("data").equals(results[i].data).delete().then(() => {
            //  console.log('abc');

          }).catch((error) => {
            console.log(error);
          })
        }
      }
    }

    catch (e) {
      console.log(e);
    }
  }

  enviaPendentes() {

    this.setState({ submitbtndisabled: true });

    db.table('gasoleo').toArray().then((lista) => {
      this.enviaPendentes2(lista);
    })

    db.table('deposito').toArray().then((lista) => {
      this.enviaPendentes3(lista);
    })

  }

  handleChange(event) {
    //  console.log(event);
    if (event === null) {

      this.setState({
        mostra_maquina: false,
        maquinaid: 0
      });

    } else {
      this.setState({
        maquinaid: event.id,
        mostra_maquina: true,
      });
    }
  }

  handleDep(event) {
    //  console.log(event);
    if (event === null) {

      this.setState({
        mostra_deposito: false,
        depositoid: 0
      });

    } else {
      this.setState({
        depositoid: event.id,
        mostra_deposito: true,
      });
    }
  }


  UNSAFE_componentWillMount() {

    //  console.log('comp will mount');
    maquinas_select = [];
    var maquinas_ = JSON.parse(localStorage.getItem('maquinas'));
    Object.entries(maquinas_).forEach(entry => {
      //    console.log(entry);
      maquinas_select.push({ id: entry[1].id, label: entry[1].matricula + ' - ' + entry[1].modelotxt });
      //   maquinas.push({ id: entry[1].id, modelo: entry[1].modelo })
    });

    PostData('mecanica/lista_maquinas.php', this.state).then((result) => {
      let responseJson = result;
      if (responseJson.error === 0) {
        localStorage.setItem('maquinas', JSON.stringify(responseJson.maquinas));
      }

    })

    depositos_select = [];
    var depositos_ = JSON.parse(localStorage.getItem('depositos'));
    Object.entries(depositos_).forEach(entry => {
      //    console.log(entry);
      depositos_select.push({ id: entry[1].id, label: entry[1].descricao + ' - Litros: '+entry[1].stock_total });

    });

    PostData('gasoleo/lista_depositos.php', this.state).then((result) => {
      let responseJson = result;
      if (responseJson.error === 0) {
        localStorage.setItem('depositos', JSON.stringify(responseJson.depositos));
      }
    })

    PostData('funcionarios/lista_funcionarios.php', this.state).then((result) => {
      let responseJson = result;
      if (responseJson.error === 0) {
        localStorage.setItem('funcionarios', JSON.stringify(responseJson.funcionarios));
      }
    })


    db.table('gasoleo').count().then(
      (count) => {
        this.setState({
          p_gas: count
        });
      }
    )
    db.table('deposito').count().then(
      (count) => {
        this.setState({
          p_dep: count
        });
      }
    )

    if ((db.table('gasoleo').count() + db.table('deposito').count()) > 0) {
      this.setState({
        mostrapendentes: true
      });
    }


  }





  render() {


    return (
      <div className="row" id="Body">
        <h4>
          Lista de Máquinas
              </h4>
        <Select
          placeholder={'Máquinas...'}
          isClearable={true}
          inputProps={{
            name: 'maquinas',
            id: 'maquinas',
          }}
          onChange={this.handleChange}
          loadOptions={maquinas_select}
          options={maquinas_select}
        />

        <p>
          {this.state.mostra_maquina ? <NavLink to={'/gasoleo/inseregasoleo/?maq=' + this.state.maquinaid}>Abastecer Gasóleo</NavLink> : ''}

        </p>
        <br></br>

        <h4>Lista de Depósitos</h4>

        <Select
          placeholder={'Depósitos...'}
          isClearable={true}
          inputProps={{
            name: 'depositos',
            id: 'depositos',
          }}
          onChange={this.handleDep}
          loadOptions={depositos_select}
          options={depositos_select}
        />


        <p>
          {this.state.mostra_deposito ? <NavLink to={'/gasoleo/inseredeposito/?dep=' + this.state.depositoid}>Abastecer Depósito</NavLink> : ''}

        </p>

        <br></br>

        {(this.state.p_gas + this.state.p_dep) > 0 ? <input type="submit" className="button success" value="Enviar Pendentes" disabled={this.state.submitbtndisabled} onClick={this.enviaPendentes} /> : ''}

        <br></br>
        <p>Últimos inseridos:</p>
<p>Disponível brevemente</p>
      </div>


    );
  }
}


export default withSnackbar(withStyles(styles)(ListaMaquinasG));
