import React, { Component } from 'react';
import { askForPermissioToReceiveNotifications } from '../../push-notifications.js';
//import {Redirect} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { PostData } from '../../services/PostData';

import db from '../../db';

const styles = theme => ({
  root: {
      display: 'flex',
      flexWrap: 'wrap',
  },
  formControl: {
      margin: theme.spacing.unit,
      minWidth: 120,
  },
  selectEmpty: {
      marginTop: theme.spacing.unit * 2,
  },
});

var loginok=1;

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {

      horas: [],
      logs: [],
      teste:''

    };


  }


  UNSAFE_componentWillMount() {

    PostData('login/test.php', this.state).then((result) => {

      //  console.log('resposta');
      //  console.log(result);

      let responseJson = result;

      if (responseJson.error === 0) {
          this.props.enqueueSnackbar(responseJson.message, { variant: 'success' });
      } else {
        if(responseJson.error>50){
          this.props.enqueueSnackbar('Sem ligação ao servidor. Utilização Limitada.', { variant: 'warning' });
        } else {
          this.props.enqueueSnackbar('Sem informação de sessão. Faça Login novamente.', { variant: 'error' });
        }
      //  loginok=0;
      //  window.location.href='/login';
       
      }
    });
  //  this.props.enqueueSnackbar('mensagem123', { variant: 'error' });
// success
// warning

  };

updateVersion(){
  window.location.reload();
}

  render() {

/*
            if (loginok==1) {
          } else {
              return (<Redirect to={'/login'}/>)
          }
  */

    return (
      <div className="row" id="Body">
        <p>
          Informações Úteis
              </p>
    
        <p>Versão 0.2.5(network first)</p>
        <p>Atualizado a 28/11/2023</p>

        <p> <button onClick={this.updateVersion} >Procurar Atualizações</button></p>
     

           </div>
    );
  }
}

export default withSnackbar(withStyles(styles)(Dashboard)); 